<template>
<div>
    <div v-if="skeletonLoading">
        <v-row>
            <v-col cols="12" md="12">
                <v-skeleton-loader
                min-height="600px"
                type="article, article, article, actions"
                >
                </v-skeleton-loader>
        </v-col>
        </v-row>
    </div>

    <div v-if="!skeletonLoading">
    <!--inicio:: condiciones de instituciones beneficiarias -->
        <v-card class="mt-6" outlined>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                        <v-subheader class="text-h6 black--text">
                            Contactos
                        </v-subheader>
                
                        <v-divider class="mt-0 pt-0"></v-divider>
                    </v-col>

                    <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
                        <v-form 
                            ref="formProyeccionDesembolsos"
                            v-on:submit.prevent="registrarContactos"
                            v-model="validForm" v-if="tipo===2">
                                <v-row>

                                    <!--inicio:: campo -->
                                    <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                                        <v-select
                                        dense
                                        filled
                                        v-model="tipoContactoSeleccionado"
                                        :items="tiposContacto"
                                        class="required"
                                        color="blue-grey lighten-2"
                                        label="Seleccione el tipo de contacto"
                                        item-text="tipo"
                                        item-value="id"
                                        :rules="[selectRequired('tipo de contacto')]"
                                        @change="configurarDatosContacto"
                                        menu-props="auto"
                                        return-object
                                        >
                                        </v-select>
                                    </v-col>

                                    <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                                        <v-text-field
                                            dense
                                            filled
                                            autocomplete="off"
                                            class="required"
                                            color="blue-grey lighten-2"
                                            v-model="datosContacto.nombre"
                                            label="Nombre del contacto"
                                            :rules="[
                                                required('nombre del contacto'),
                                                minLength('nombre del contacto', 5),
                                                maxLength('nombre del contacto', 100)
                                            ]"
                                            maxlength="100"
                                            ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                                        <v-select
                                            dense
                                            filled
                                            v-model="datosContacto.cargosId"
                                            :items="cargos"
                                            class="required"
                                            color="blue-grey lighten-2"
                                            :loading="false"
                                            label="Cargo"
                                            item-text="nombreCargo"
                                            item-value="id"
                                            :rules="[tipoContactoSeleccionado.id === 2 ? selectRequired('cargo') : true]"
                                            menu-props="auto"
                                            >
                                        </v-select>
                                    </v-col>

                                    <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                                        <v-text-field
                                            dense
                                            filled
                                            autocomplete="off"
                                            class="required"
                                            color="blue-grey lighten-2"
                                            v-model="datosContacto.area"
                                            label="Área a la que pertenece el contacto"
                                            :rules="[
                                                required('área'),
                                                minLength('área', 5),
                                                maxLength('área', 100),
                                            
                                            ]"
                                            maxlength="100"
                                            ></v-text-field>
                                    </v-col>
                                </v-row>


                                <v-row>
                                    <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                                        <v-text-field
                                            dense
                                            filled
                                            autocomplete="off"
                                            class="required"
                                            color="blue-grey lighten-2"
                                            v-model="datosContacto.correoElectronicoInstitucional"
                                            label="Correo Institucional"
                                            :rules="[
                                                required('correo  Institucional'),
                                                minLength('correo  Institucional', 5),
                                                maxLength('correo  Institucional', 150),
                                                validEmail('correo  Institucional')
                                            ]"
                                            maxlength="150"
                                            ></v-text-field>
                                    </v-col>


                                    <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                                        <v-text-field
                                            dense
                                            filled
                                            autocomplete="off"
                                            class="required"
                                            color="blue-grey lighten-2"
                                            v-model="datosContacto.telefonoInstitucional"
                                            label="Teléfono institucional"
                                            :rules="[
                                                required('teléfono institucional'),
                                                minLength('teléfono institucional', 8),
                                                maxLength('teléfono institucional', 15),
                                            ]"
                                            maxlength="15"
                                            ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                                        <v-text-field
                                            dense
                                            filled
                                            autocomplete="off"
                                            color="blue-grey lighten-2"
                                            v-model="datosContacto.telefonoPersonal"
                                            label="Teléfono personal"
                                            :rules="[
                                                maxLength('teléfono personal', 15),
                                            ]"
                                            maxlength="15"
                                        ></v-text-field>
                                    </v-col>

                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12" sm="12" class="pt-0">
                                            
                                        <v-btn
                                            color="primary"
                                            class="mb-2 float-right"
                                            type="submit"
                                            large
                                            :elevation="0"
                                            :disabled="!validForm"
                                            :loading="btnRegistroLoading"
                                        >
                                            Guardar contacto
                                        </v-btn>
                                    </v-col>


                                </v-row>
                            </v-form>
                    </v-col>
                </v-row>


                <v-row class="mt-4" v-if="tipo===2">
                    <v-col cols="12" md="12" sm="12">
                        <v-subheader class="text-h6 black--text">
                            Contactos registrados 
                        </v-subheader>
                        <v-divider class="mt-0 pt-0"></v-divider>
                    </v-col>
                </v-row>

                <v-row>
                    <!--inicio:: tabla de documentos cargados -->
                    <v-col cols="12" md="12" sm="12" class="pt-4">
                        <v-data-table
                            class="elevation-1"
                            :headers="headersContactos"
                            :items="contactos"
                            :loading="tableContactosLoading"
                            hide-default-footer
                            :footer-props="{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                'items-per-page-text': 'Registros por página',
                                pageText: '{0}-{1} de {2}'
                            }"
                            >

                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td>{{ item.tipo }}</td>
                                        <td>{{ item.nombre }}</td>
                                        <td>{{ item.area }}</td>
                                        <td>{{ item.nombreCargo }}</td>
                                        <td> 
                                             <small class="text-subtitle-2" v-if="item.correoElectronicoPersonal">personal: {{ item.correoElectronicoPersonal }}<br/></small>
                                             <small class="text-subtitle-2" v-if="item.correoElectronicoInstitucional">institucional: {{ item.correoElectronicoInstitucional }} </small>
                                        </td>
                                        <td>
                                            <small class="text-subtitle-2" v-if="item.telefonoPersonal">personal: {{item.telefonoPersonal}} <br/></small>
                                            <small class="text-subtitle-2" v-if="item.telefonoInstitucional">institucional: {{ item.telefonoInstitucional }} </small>
                                        </td>
                                        <td>
                                            <v-chip
                                                class="ma-2 font-weight-medium"
                                                label
                                                :color="
                                                    item.estadosId === 1
                                                    ? 'blue lighten-4'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-4'
                                                    : item.estadosId === 6
                                                    ? 'cyan lighten-4'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-4'
                                                    : 'pink lighten-4'
                                                "
                                                :text-color="
                                                    item.estadosId === 1
                                                    ? 'blue lighten-1'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-1'
                                                    : item.estadosId === 6
                                                    ? 'cyan darken-1'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-1'
                                                    : 'pink darken-1'
                                                "
                                                small
                                                >
                                                {{ item.estado }}
                                            </v-chip>
                                        </td>
                                        <td>
                                            <v-btn v-if="item.estadosId==1 && tipo===2"
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                :disabled="btnEliminarDisabled"
                                                color="blue-grey lighten-5"
                                                @click="eliminarContacto(item.id)"
                                                >
                                                <v-icon left>mdi-delete</v-icon> Dar de baja
                                            </v-btn>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                  </v-row>
            </v-card-text>
        </v-card>



        <SnackAlert ref="snackalert"></SnackAlert>
        <!-- Fin:: Snack alert-->

        <!--Dialog loader -->
        <DialogLoader
        :dialogVisible="dialogLoaderVisible"
        :text="dialogLoaderText"
        ></DialogLoader>
        <!---->
    </div>
</div>
</template>


<script>
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
import {OBTENER_ITEMS_CATALOGO} from "@/core/services/store/catalogos/catalogointerno.module";
import { OBTENER_CONTACTOS, REGISTRAR_CONTACTO, ELIMINAR_CONTACTO } from "@/core/services/store/contactos/contacto.module";

export default {
    name: 'SeccionContactosAlianza',
    components: {
        DialogLoader,
        SnackAlert
    },
    props: ['alianzaId', 'accion', 'tipo'], //Tipo 1 segepla, 2 externo, si es 2 bloquear los controles
    data(){
        return {
            skeletonLoading:false,
            validForm: false,
            btnRegistroLoading: false,
            dialogLoaderVisible:false,
            btnEliminarDisabled: false,
            dialogLoaderText: '',
            tableContactosLoading: false,
            tiposContacto: [],
            tipoContactoSeleccionado: {},
            cargos: [],
            contactos: [],
            datosContacto: {
                nombre: '',
                cargosId: null,
                area: '',
                correoElectronicoPersonal: "",
                telefonoPersonal: "",
                tipo: 2,
                correoElectronicoInstitucional: '',
                telefonoInstitucional: "",
                estadosId: 1,
                usuarioCreacion: 'admin',
                instrumentoAlianzaId: this.alianzaId
            },
            ...validations
        }
    },

    methods:{

        //Restablecer los campos
        resetItems(tipo){
            if(tipo===2){
                this.skeletonLoading=true;
            }
            this.contactos = [];
            this.tipoContactoSeleccionado= {};
            
            this.datosContacto= {
                nombre: '',
                cargosId: null,
                area: '',
                tipo: 2,
                correoElectronicoPersonal: "",
                telefonoPersonal: "",
                correoElectronicoInstitucional: '',
                telefonoInstitucional: "",
                estadosId: 1,
                usuarioCreacion: 'admin'
            };
        },

        //Obtener los tipos de contacto
        async obtenerTiposContacto() {
            this.tiposContacto = [];
            await this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TipoContacto/all/1/1'})
                .then(res => {
                if (res.status === 200) {
                    this.tiposContacto = res.data;
                }
                })
                .catch(() => {
                    this.tiposContacto = [];
                });
        },

        //Obtener los cargos
        async obtenerCargos() {
            this.cargos = [];
            await this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Cargos/all/1'})
                .then(res => {
                if (res.status === 200) {
                    this.cargos = res.data;
                }
                })
                .catch(() => {
                    this.cargos = [];
                });
        },
        
        //Obtener la información de contactos del proyecto
        async obtenerContactos(alianzaId){

            this.contactos = [];
            this.tableContactosLoading=true;
         
            await this.$store
                .dispatch(OBTENER_CONTACTOS, {tipoContacto: 'ALIANZA', estadoId: 0, id: alianzaId})
                .then(res => {
                    if(res.status === 200) {
                        this.contactos = res.data;
                    }
                     this.skeletonLoading=false;
                     this.tableContactosLoading=false;
                })
                .catch(() => {
                     this.skeletonLoading=false;
                     this.tableContactosLoading=false;
                });

        },

        //Configurar los contactos en base al tipo
        configurarDatosContacto(){
            
        },

        async registrarContactos(){
            
            this.btnRegistroLoading=true;
            this.datosContacto.tipo = 2;
            this.datosContacto.estadosId= 1;
            this.datosContacto.usuarioCreacion ='admin';
            this.datosContacto.tipoContactoId = this.tipoContactoSeleccionado.id;
            this.datosContacto.instrumentoAlianzaId = this.alianzaId
           
            await this.$store
            .dispatch(REGISTRAR_CONTACTO, {datos: this.datosContacto})
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.obtenerContactos(this.alianzaId);             
                    this.resetItems();
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnRegistroLoading=false;
            })
            .catch(error => {
                
                 this.btnRegistroLoading=false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        },


        async eliminarContacto(id){
            this.btnEliminarDisabled=true;
            await this.$store
            .dispatch(ELIMINAR_CONTACTO, id)
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.obtenerContactos(this.alianzaId);             
                    this.resetItems();
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnEliminarDisabled=false;
            })
            .catch(error => {
                
                 this.btnEliminarDisabled=false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        }
    },

    created() {
        this.obtenerCargos().then(() => {
            this.obtenerTiposContacto().then(() => {
                this.obtenerContactos(this.alianzaId);
            });

        })
    },

    computed: {
        headersContactos(){
        return [
            {
                text: "Id",
                //align: "start",
                sortable: false,
                value: "id",
                align: ' d-none'
            },
            {
                text: "Tipo Contacto",
                align: "start",
                sortable: true,
                value: "tipo"
            },
            {
                text: "Nombre",
                align: "start",
                sortable: true,
                value: "nombre"
            },
            {
                text: "Área",
                align: "start",
                sortable: true,
                value: "area"
            },
            {
                text: "Cargo",
                align: "start",
                sortable: true,
                value: "cargo"
            },
            {
                text: "Correo(s)",
                align: "start",
                sortable: true,
                value: "correo"
            },
            {
                text: "Teléfono(s)",
                align: "start",
                sortable: true,
                value: "telefono"
            },
             {
                text: "Estado",
                align: "start",
                sortable: true,
                value: "estado"
            },
            {
                text: "Acciones",
                align: "start",
                sortable: false,
                value: ""
            }
        ]
        }
    }
}
</script>