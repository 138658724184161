<template>
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <span class="card-label font-weight-bolder text-dark">
              Bandeja alianzas
            </span>
          </v-col>
          <!--<v-col cols="12" sm="12" md="6">
                    <v-btn
                    color=""
                    dark
                    class="mb-2 float-right light-blue darken-4"
                    @click="modalNuevo"
                    >
                    Registrar alianza
                    </v-btn>
                </v-col>-->
        </v-row>

        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-tabs
          v-model="tabGrid"
          background-color="light-blue-502"
          centered
          dark
          icons-and-text
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1">
            En verificación
            <v-icon>mdi-check</v-icon>
          </v-tab>

          <v-tab href="#tab-2">
            Todas las alianzas
            <v-icon>mdi-format-list-bulleted-square</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabGrid" touchless>
          <v-tab-item :value="'tab-1'">
            <v-card flat>
              <v-card-text>
                <!--incio:: listado alianzas -->
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      class="elevation-0"
                      :headers="headers"
                      :items="alianzas"
                      :search="filtro"
                      :loading="tableLoading"
                      :header-props="{
                        sortByText: 'Ordenar por'
                      }"
                      locale="es"
                      no-data-text="No existe información registrada"
                      :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'mdi-page-first',
                        lastIcon: 'mdi-page-last',
                        prevIcon: 'mdi-chevron-left',
                        nextIcon: 'mdi-chevron-right',
                        'items-per-page-text': 'Registros por página',
                        pageText: '{0}-{1} de {2}'
                      }"
                    >
                      <template v-slot:top>
                        <!-- v-container, v-col and v-row are just for decoration purposes. -->
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12" md="12" sm="12" xs="12">
                              <v-text-field
                                autocomplete="off"
                                class="required"
                                dense
                                filled
                                color="blue-grey lighten-2"
                                v-model="filtro"
                                label="Nombre de la iniciativa, código..."
                                maxlength="200"
                              ></v-text-field>
                            </v-col>

                            <!-- <v-col cols="12" md="6" sm="6" xs="12">
                                                        <v-select
                                                            v-model="estado"
                                                            :items="estados"
                                                            :loading="false"
                                                            dense
                                                            filled
                                                            label="Estado"
                                                            :no-data-text="'No existen estados registrados'"
                                                            menu-props="auto"
                                                            return-object
                                                        ></v-select>
                                                    </v-col> -->

                            <v-col
                              cols="2"
                              md="2"
                              sm="12"
                              xs="6"
                              class="d-none"
                            >
                              <v-btn
                                class="ma-0 white--text d-none"
                                medium
                                color="light-blue darken-2"
                              >
                                <v-icon left>mdi-magnify</v-icon> Buscar
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </template>

                      <template v-slot:item="{ item }">
                        <tr>
                          <td>{{ item.id }}</td>
                          <td>{{ item.nombreInstrumento }}</td>
                          <td>{{ item.fechaSuscripcion }}</td>
                          <td>{{ item.fechaFinalizacion }}</td>
                          <td>
                            <v-chip
                              class="ma-2 font-weight-medium"
                              label
                              :color="
                                item.estadosId === 1
                                  ? 'blue-grey lighten-4'
                                  : item.estadosId === 2
                                  ? 'blue lighten-4'
                                  : item.estadosId === 6
                                  ? 'cyan lighten-4'
                                  : item.estadosId === 3
                                  ? 'teal lighten-4'
                                  : item.estadosId === 4
                                  ? 'deep-orange lighten-4'
                                  : 'pink lighten-4'
                              "
                              :text-color="
                                item.estadosId === 1
                                  ? 'blue-grey lighten-1'
                                  : item.estadosId === 2
                                  ? 'blue lighten-1'
                                  : item.estadosId === 6
                                  ? 'cyan darken-1'
                                  : item.estadosId === 3
                                  ? 'teal lighten-1'
                                  : item.estadosId === 4
                                  ? 'deep-orange lighten-1'
                                  : 'pink darken-1'
                              "
                              small
                            >
                              {{ item.estado }}
                            </v-chip>
                          </td>
                          <td>
                            <!-- <v-btn
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                color="blue-grey lighten-5"
                                                @click="descargarArchivo"
                                                >
                                                <v-icon left>mdi-download</v-icon> Documento de formalización
                                                </v-btn> -->

                            <v-btn
                              class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                              small
                              depressed
                              color="blue-grey lighten-5"
                              @click="mostrarModalActualizar(item.id)"
                            >
                              <v-icon left>mdi-eye</v-icon> Ver información
                            </v-btn>

                            <v-btn
                              class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                              small
                              depressed
                              color="blue-grey lighten-5"
                              :href="
                                `http://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/Alinazas/InstrumentoAlianza.pdf?endpoint=ReporteAlianzas/GetById/${item.id}&j_password=reportes&j_username=reporte`
                              "
                              target="_blank"
                            >
                              <v-icon left>mdi-file-download</v-icon> Descargar
                              reporte
                            </v-btn>

                            <!-- <v-btn
                                                v-if="item.estadosId === 1 || item.estadosId === 4"
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize "
                                                small
                                                depressed
                                                color="blue-grey lighten-5"
                                                @click="prepararEnvio(item.id)"
                                                >
                                                <v-icon left>mdi-send</v-icon> Enviar
                                                </v-btn>-->

                            <!--<v-btn
                                                v-if="item.estadosId === 3"
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                color="blue-grey lighten-5"
                                                :to="{
                                                    name: 'negociacion-directa-proyectos',
                                                    params: {
                                                    negociacionId: $CryptoJS.AES.encrypt(
                                                        item.id.toString(),
                                                        'KEYADMINCNS2022'
                                                    ).toString()
                                                    }
                                                }"
                                                >
                                                <v-icon left>mdi-file</v-icon> Ver iniciativas
                                                </v-btn>-->

                            <v-menu top :close-on-click="true">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="orange darken-2"
                                  icon
                                  fab
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="item.asignacionActores === 0"
                                >
                                  <v-icon color="orange darken-2">info</v-icon>
                                </v-btn>
                              </template>

                              <v-list>
                                <v-list-item>
                                  <v-list-item-title
                                    >Debe agregar roles y actores para poder
                                    registrar iniciativas.</v-list-item-title
                                  >
                                </v-list-item>
                              </v-list>
                            </v-menu>

                            <v-btn
                              v-if="item.estadosId === 7"
                              class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                              small
                              depressed
                              color="blue-grey lighten-5"
                              @click="
                                mostrardialogEnvio(item.nombreInstrumento)
                              "
                            >
                              <v-icon left>mdi-power</v-icon> Activar
                            </v-btn>

                            <!--<v-btn
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                color="blue-grey lighten-5"
                                                :to="{
                                                    name: 'seguimiento-iniciativa',
                                                    params: {
                                                    iniciativaId: $CryptoJS.AES.encrypt(
                                                        item.id.toString(),
                                                        'KEYADMINCNS2022'
                                                    ).toString()
                                                    }
                                                }"
                                                >
                                                <v-icon left>mdi-arrow-collapse-right</v-icon> Seguimiento
                                                </v-btn>-->

                            <v-btn
                              v-if="item.estadosId === 2"
                              class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                              small
                              depressed
                              color="blue-grey lighten-5"
                              @click="
                                mostrarModalAprobacionRechazo(
                                  1,
                                  item.id,
                                  item.nombreInstrumento
                                )
                              "
                            >
                              <v-icon left>mdi-check-all</v-icon> Aprobar
                            </v-btn>

                            <v-btn
                              v-if="item.estadosId === 2"
                              class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                              small
                              depressed
                              color="blue-grey lighten-5"
                              @click="
                                mostrarModalAprobacionRechazo(
                                  2,
                                  item.id,
                                  item.nombreInstrumento
                                )
                              "
                            >
                              <v-icon left>mdi-close-circle-outline</v-icon>
                              Rechazar
                            </v-btn>

                            <v-btn
                              v-if="item.estadosId === 2"
                              class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                              small
                              depressed
                              color="blue-grey lighten-5"
                              @click="
                                mostrarDialogCorreccion(
                                  item.id,
                                  item.nombreInstrumento
                                )
                              "
                            >
                              <v-icon left>mdi-playlist-edit</v-icon> Enviar
                              corrección
                            </v-btn>

                            <v-menu
                              v-if="item.estadoId != 3"
                              v-model="menuHistorial[item.id]"
                              class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                              :close-on-content-click="false"
                              :nudge-width="300"
                              offset-x
                            >
                              <template v-slot:activator="{ on, attrsMenu }">
                                <v-btn
                                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                  color="blue-grey lighten-5"
                                  dark
                                  small
                                  depressed
                                  v-bind="attrsMenu"
                                  v-on="on"
                                  @click="mostrarHistorialNegociacion(item.id)"
                                >
                                  <v-icon left>mdi-eye</v-icon> Historial de
                                  revisiones
                                </v-btn>
                              </template>

                              <v-card class="elevation-0">
                                <div v-if="skeletonHistorial[item.id]">
                                  <v-row>
                                    <v-col cols="12" md="12">
                                      <v-skeleton-loader
                                        v-bind="attrs"
                                        min-height="200px"
                                        type="article, article"
                                      >
                                      </v-skeleton-loader>
                                    </v-col>
                                  </v-row>
                                </div>

                                <div v-if="!skeletonHistorial[item.id]">
                                  <v-list subheader>
                                    <v-subheader class="pl-4"
                                      >Historial</v-subheader
                                    >

                                    <v-list-item
                                      v-for="(item, i) in datosHistorial"
                                      :key="i"
                                      style="height: 25px; margin-top: 5px;"
                                    >
                                      <v-list-item-content>
                                        <v-list-item-title
                                          >{{ item.estadoNegociacion }} |
                                          {{
                                            item.fechaCreacion
                                          }}</v-list-item-title
                                        >
                                        <v-list-item-subtitle
                                          v-if="item.comentarios"
                                          ><div
                                            v-html="addBr(item.comentarios)"
                                          ></div
                                        ></v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </div>
                              </v-card>
                            </v-menu>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <!--fin:: listado alianzas -->
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :value="'tab-2'">
            <v-card flat>
              <v-card-text>
                <!--incio:: listado alianzas -->
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      class="elevation-0"
                      :headers="headersAll"
                      :items="alianzasAll"
                      :search="filtroAll"
                      no-data-text="No existe información registrada"
                      locale="es"
                      :loading="tableLoadingAll"
                      :header-props="{
                        sortByText: 'Ordenar por'
                      }"
                      :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'mdi-page-first',
                        lastIcon: 'mdi-page-last',
                        prevIcon: 'mdi-chevron-left',
                        nextIcon: 'mdi-chevron-right',
                        'items-per-page-text': 'Registros por página',
                        pageText: '{0}-{1} de {2}'
                      }"
                    >
                      <template v-slot:top>
                        <!-- v-container, v-col and v-row are just for decoration purposes. -->
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12" md="12" sm="12" xs="12">
                              <v-text-field
                                autocomplete="off"
                                class="required"
                                dense
                                filled
                                color="blue-grey lighten-2"
                                v-model="filtroAll"
                                label="Nombre de la alianza, código..."
                                maxlength="200"
                              ></v-text-field>
                            </v-col>

                            <!-- <v-col cols="12" md="6" sm="6" xs="12">
                                                        <v-select
                                                            v-model="estado"
                                                            :items="estados"
                                                            :loading="false"
                                                            dense
                                                            filled
                                                            label="Estado"
                                                            :no-data-text="'No existen estados registrados'"
                                                            menu-props="auto"
                                                            return-object
                                                        ></v-select>
                                                    </v-col>
                    -->
                            <v-col
                              cols="2"
                              md="2"
                              sm="12"
                              xs="6"
                              class="d-none"
                            >
                              <v-btn
                                class="ma-0 white--text d-none"
                                medium
                                color="light-blue darken-2"
                              >
                                <v-icon left>mdi-magnify</v-icon> Buscar
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </template>

                      <template v-slot:item="{ item }">
                        <tr>
                          <td>{{ item.id }}</td>
                          <td>{{ item.nombreInstrumento }}</td>
                          <td class="text-right">
                            {{ item.fechaSuscripcion }}
                          </td>
                          <td class="text-right">
                            {{ item.fechaFinalizacion }}
                          </td>
                          <td>
                            <v-chip
                              class="ma-2 font-weight-medium"
                              label
                              :color="
                                item.estadosId === 1
                                  ? 'blue-grey lighten-4'
                                  : item.estadosId === 2
                                  ? 'blue lighten-4'
                                  : item.estadosId === 6
                                  ? 'cyan lighten-4'
                                  : item.estadosId === 3
                                  ? 'teal lighten-4'
                                  : item.estadosId === 4
                                  ? 'deep-orange lighten-4'
                                  : 'pink lighten-4'
                              "
                              :text-color="
                                item.estadosId === 1
                                  ? 'blue-grey lighten-1'
                                  : item.estadosId === 2
                                  ? 'blue lighten-1'
                                  : item.estadosId === 6
                                  ? 'cyan darken-1'
                                  : item.estadosId === 3
                                  ? 'teal lighten-1'
                                  : item.estadosId === 4
                                  ? 'deep-orange lighten-1'
                                  : 'pink darken-1'
                              "
                              small
                            >
                              {{ item.estado }}
                            </v-chip>
                          </td>
                          <td>
                            <v-btn
                              class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                              small
                              depressed
                              color="blue-grey lighten-5"
                              @click="mostrarModalActualizar(item.id)"
                            >
                              <v-icon left>mdi-eye</v-icon> Ver información
                            </v-btn>
                            <v-btn
                              class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                              small
                              depressed
                              color="blue-grey lighten-5"
                              @click="
                                descargarArchivo(item.documentoFormalizacion)
                              "
                            >
                              <v-icon left>mdi-download</v-icon> Documento de
                              formalización formalización
                            </v-btn>

                            <!-- <v-btn v-if="item.estadosId === 1 || item.estadosId===9"
                                                    class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                    small
                                                    depressed
                                                    color="blue-grey lighten-5"
                                                    @click="mostrarModalActualizar(item.id)"
                                                    >
                                                    <v-icon left>mdi-eye</v-icon> Ver información
                                                </v-btn> -->

                            <!-- <v-btn
                                                v-if="item.estadosId === 1 || item.estadosId === 4"
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize "
                                                small
                                                depressed
                                                color="blue-grey lighten-5"
                                                @click="prepararEnvio(item.id)"
                                                >
                                                <v-icon left>mdi-send</v-icon> Enviar
                                                </v-btn>-->

                            <v-btn
                              v-if="item.estadosId === 3"
                              class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                              small
                              depressed
                              color="blue-grey lighten-5"
                              :to="{
                                name: 'proyectos-alianzas',
                                params: {
                                  alianzaId: $CryptoJS.AES.encrypt(
                                    item.id.toString(),
                                    'KEYADMINCNS2022'
                                  ).toString()
                                }
                              }"
                            >
                              <v-icon left>mdi-file</v-icon> Proyectos
                            </v-btn>

                            <v-menu top :close-on-click="true">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="orange darken-2"
                                  icon
                                  fab
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="item.asignacionActores === 0"
                                >
                                  <v-icon color="orange darken-2">info</v-icon>
                                </v-btn>
                              </template>

                              <v-list>
                                <v-list-item>
                                  <v-list-item-title
                                    >Debe agregar roles y actores para poder
                                    registrar iniciativas.</v-list-item-title
                                  >
                                </v-list-item>
                              </v-list>
                            </v-menu>

                            <v-btn
                              v-if="item.estadosId === 7"
                              class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                              small
                              depressed
                              color="blue-grey lighten-5"
                              @click="mostrardialogEnvio(item.nombre)"
                            >
                              <v-icon left>mdi-power</v-icon> Activar
                            </v-btn>
                            <v-btn
                              class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                              small
                              depressed
                              color="blue-grey lighten-5"
                              :href="
                                `http://rpts.sicoopera.gt/jasperserver/rest_v2/reports/Reportes/Alinazas/InstrumentoAlianza.pdf?endpoint=ReporteAlianzas/GetById/${item.id}&j_password=reportes&j_username=reporte`
                              "
                              target="_blank"
                            >
                              <v-icon left>mdi-file-download</v-icon> Descargar
                              reporte
                            </v-btn>

                            <!--<v-btn
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                color="blue-grey lighten-5"
                                                :to="{
                                                    name: 'seguimiento-iniciativa',
                                                    params: {
                                                    iniciativaId: $CryptoJS.AES.encrypt(
                                                        item.id.toString(),
                                                        'KEYADMINCNS2022'
                                                    ).toString()
                                                    }
                                                }"
                                                >
                                                <v-icon left>mdi-arrow-collapse-right</v-icon> Seguimiento
                                                </v-btn>-->

                            <!-- <v-btn v-if="item.estadosId===1 || item.estadosId === 9"
                                                    class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                    small
                                                    depressed
                                                    color="blue-grey lighten-5"
                                                    @click="mostrarModalEnviarAlianza(item.id, item.nombreInstrumento)"
                                                >
                                                <v-icon left>mdi-send</v-icon> Enviar
                                                </v-btn> -->

                            <v-menu
                              v-if="item.estadoId != 3"
                              v-model="menuHistorial[item.id]"
                              class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                              :close-on-content-click="false"
                              :nudge-width="300"
                              offset-x
                            >
                              <template v-slot:activator="{ on, attrsMenu2 }">
                                <v-btn
                                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                  color="blue-grey lighten-5"
                                  dark
                                  small
                                  depressed
                                  v-bind="attrsMenu2"
                                  v-on="on"
                                  @click="mostrarHistorialNegociacion(item.id)"
                                >
                                  <v-icon left>mdi-eye</v-icon> Historial de
                                  revisiones
                                </v-btn>
                              </template>

                              <v-card class="elevation-0">
                                <div v-if="skeletonHistorial[item.id]">
                                  <v-row>
                                    <v-col cols="12" md="12">
                                      <v-skeleton-loader
                                        v-bind="attrs"
                                        min-height="200px"
                                        type="article, article"
                                      >
                                      </v-skeleton-loader>
                                    </v-col>
                                  </v-row>
                                </div>

                                <div v-if="!skeletonHistorial[item.id]">
                                  <v-list subheader>
                                    <v-subheader class="pl-4"
                                      >Historial</v-subheader
                                    >

                                    <v-list-item
                                      v-for="(item, i) in datosHistorial"
                                      :key="i"
                                      style="height: 25px; margin-top: 5px;"
                                    >
                                      <v-list-item-content>
                                        <v-list-item-title
                                          >{{ item.estadoNegociacion }} |
                                          {{
                                            item.fechaCreacion
                                          }}</v-list-item-title
                                        >
                                        <v-list-item-subtitle
                                          v-if="item.comentarios"
                                          ><div
                                            v-html="addBr(item.comentarios)"
                                          ></div
                                        ></v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </div>
                              </v-card>
                            </v-menu>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>

    <!-- inicio: dialog de registro -->
    <v-dialog
      v-model="dialogRegistro"
      max-width="1024px"
      transition="scroll-y-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title>
          {{ modalTitle }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="dialogRegistro = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text class="pl-0 pr-0 pt-0">
          <v-tabs
            v-model="tab"
            background-color="light-blue-502"
            centered
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              Datos Generales
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-tab>

            <v-tab href="#tab-2" :disabled="accion === 1">
              Vinculación a marcos de desarrollo
              <v-icon>mdi-map-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-3" :disabled="accion === 1">
              Socios
              <v-icon>mdi-account-group</v-icon>
            </v-tab>
            <!--<v-tab href="#tab-4" :disabled="accion===1">
                    Vinculación a marcos de desarrollo
                    <v-icon>mdi-map-outline</v-icon>
                    </v-tab>-->

            <v-tab href="#tab-4" :disabled="accion === 1">
              Cobertura
              <v-icon>mdi-map-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-5" :disabled="accion === 1">
              Contactos
              <v-icon>mdi-account-box-outline</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" touchless>
            <v-tab-item :value="'tab-1'">
              <v-card flat>
                <v-card-text>
                  <SeccionDatosGeneralesAlianza
                    ref="seccionDatosGeneralesAlianza"
                    :tipo="tipo"
                    :alianzaId="alianzaId"
                    :accion="accion"
                    @get-items="obtenerAlianzas"
                    @habilitar-secciones="habilitarSecciones"
                    @actualizar-alianza-id="actualizarAlianzaId"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-2'">
              <v-card flat>
                <v-card-text>
                  <SeccionVinculacionAlianza
                    ref="seccionVinculacionAlianza"
                    :tipo="tipo"
                    :alianzaId="alianzaId"
                    :accion="accion"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-3'">
              <v-card flat>
                <v-card-text>
                  <SeccionSociosAlianza
                    ref="seccionSociosAlianza"
                    :tipo="tipo"
                    :alianzaId="alianzaId"
                    :accion="accion"
                    @get-items="obtenerAlianzas"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-4'">
              <v-card flat>
                <v-card-text>
                  <SeccionCoberturaAlianza
                    ref="seccionCoberturaAlianza"
                    :tipo="tipo"
                    :alianzaId="alianzaId"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-5'">
              <v-card flat>
                <v-card-text>
                  <SeccionContactosAlianza
                    ref="seccionContactosAlianza"
                    :tipo="tipo"
                    :alianzaId="alianzaId"
                    :accion="accion"
                    @get-items="obtenerAlianzas"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- fin:: dialog registro alianza-->

    <!--begin:: dialog envio alianza -->
    <v-dialog
      v-model="dialogEnvio"
      width="500"
      transition="scroll-y-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          Enviar instrumento de alianza

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnEnvioLoading"
            persistent
            transition="dialog-bottom-transition"
            @click="
              dialogEnvio = false;
              comentarios = '';
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="text-h6 black--text">
                {{ nombreInstrumento }}
              </p>
              <p class="text-subtitle-1">
                Está seguro de
                {{ nuevoEstado === 3 ? `Aprobar` : `Rechazar` }} la negociación
                seleccionada?
              </p>

              <v-row>
                <!--inicio:: campo -->
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                  <v-textarea
                    dense
                    filled
                    class="required"
                    v-model="comentarios"
                    color="blue-grey lighten-2"
                    label="Ingrese sus comentarios"
                    :rules="[
                      required('comentarios'),
                      maxLength('comentarios', 500)
                    ]"
                    rows="3"
                    maxlength="500"
                  >
                  </v-textarea>
                </v-col>
                <!--fin:: campo-->
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogEnvio = false"
            :disabled="btnEnvioLoading"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="enviarAlianza"
            :loading="btnEnvioLoading"
            :disabled="comentarios.length <= 0"
          >
            Sí, {{ nuevoEstado === 3 ? `Aprobar` : `Rechazar` }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog envío-->

    <!--begin:: dialog correccion -->
    <v-dialog
      v-model="dialogCorreccion"
      persistent
      width="500"
      transition="scroll-y-transition"
    >
      <v-card>
        <v-card-title class="text-h5">
          Corrección de instrumento alianza

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnAprobacionLoading"
            transition="dialog-bottom-transition"
            @click="
              dialogCorreccion = false;
              comentarios = '';
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="formAprobacion"
            v-on:submit.prevent="enviarAlianza()"
            v-model="validFormCorreccion"
          >
            <v-row>
              <v-col cols="12">
                <p class="text-subtitle-1">
                  Ingrese sus comentarios de corrección/correcciones
                </p>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      autocomplete="off"
                      class="required"
                      dense
                      filled
                      rows="4"
                      color="blue-grey lighten-2"
                      v-model="comentarios"
                      label="Agregue sus comentarios"
                      :rules="[
                        required('comentarios'),
                        minLength('comentarios', 10),
                        maxLength('comentarios', 500)
                      ]"
                      maxlength="500"
                    >
                    </v-textarea>
                    <!--<div
                    class="blue-grey lighten-5 pa-4 text-center secondary rounded-lg"
                  >
                    <p class="text-subtitle-1">
                      {{ datosItem.descripcion }}
                    </p>
                  </div>-->
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-spacer></v-spacer>
                <v-btn
                  color="green--text darken-1--text"
                  text
                  class="float-right"
                  type="submit"
                  :loading="btnAprobacionLoading"
                  :disabled="!validFormCorreccion"
                >
                  <v-icon>mdi-check-all</v-icon>
                  Enviar Corrección
                </v-btn>

                <v-btn
                  color="blue-grey darken-2"
                  text
                  class="float-right"
                  @click="dialogCorreccion = false"
                  :disabled="btnAprobacionLoading"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--end: dialog correcciones-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
import DownloadFile from "@/core/untils/downloadfile.js";
import {
  OBTENER_INSTRUMENTOS_ALIANZA,
  ENVIAR_ALIANZA
} from "@/core/services/store/alianzas/instrumentoalianza.module";

import SeccionDatosGeneralesAlianza from "@/view/pages/alianzas/secciones/SeccionDatosGeneralesAlianza.vue";
import SeccionVinculacionAlianza from "@/view/pages/alianzas/secciones/SeccionVinculacionAlianza.vue";
import SeccionSociosAlianza from "@/view/pages/alianzas/secciones/SeccionSociosAlianza.vue";
import SeccionCoberturaAlianza from "@/view/pages/alianzas/secciones/SeccionCoberturaAlianza.vue";
import SeccionContactosAlianza from "@/view/pages/alianzas/secciones/SeccionContactosAlianza.vue";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
//import { OBTENER_INICIATIVAS } from "@/core/services/store/negociaciones/iniciativadirecta.module";

export default {
  name: "AdminAlianzas",
  components: {
    DialogLoader,
    SnackAlert,
    SeccionDatosGeneralesAlianza,
    SeccionVinculacionAlianza,
    SeccionSociosAlianza,
    SeccionCoberturaAlianza,
    SeccionContactosAlianza
  },
  data() {
    return {
      accion: 1,
      tableLoading: false,
      alianzas: [],
      alianzaId: 0,

      alianzasAll: [],
      filtroAll: "",
      tableLoadingAll: false,
      nombreInstrumento: "",
      dialogRegistro: false,
      modalTitle: "Instrumento alianza",
      btnRegistroLoading: false,
      tab: "tab-1",
      tabGrid: "tab-1",
      dialogEnvio: false,
      btnEnvioLoading: false,
      comentarios: "",
      nuevoEstado: 0,
      filtro: "",
      tipo: 1, //Representa desde donde se está realizando la accion, (1 interno segeplan, 2 externo)
      dialogCorreccion: false,
      btnAprobacionLoading: false,
      menuHistorial: [],
      datosHistorial: [],
      skeletonHistorial: [],
      ...validations
    };
  },

  methods: {
    modalNuevo() {
      this.tab = "tab-1";
      this.accion = 1;
      this.alianzaId = 0;
      this.modalTitle = "Instrumento alianza";
      if (this.$refs.seccionDatosGeneralesAlianza) {
        this.$refs.seccionDatosGeneralesAlianza.obtenerTiposInstrumento();
        this.$refs.seccionDatosGeneralesAlianza.obtenerClasificacionesRecursos();

        this.$refs.seccionDatosGeneralesAlianza.resetItems(2);
      }

      if (this.$refs.seccionVinculacionAlianza) {
        this.$refs.seccionVinculacionAlianza.resetItems(2);
      }

      if (this.$refs.seccionSociosAlianza) {
        this.$refs.seccionSociosAlianza.resetItems(2);
        this.$refs.seccionSociosAlianza.obtenerTiposSocio();
      }

      if (this.$refs.seccionCoberturaAlianza) {
        this.$refs.seccionCoberturaAlianza.resetItems(2);
        this.$refs.seccionCoberturaAlianza.obtenerDepartamentos();
      }

      if (this.$refs.seccionContactosAlianza) {
        this.$refs.seccionContactosAlianza.resetItems(2);
      }

      if (this.$refs.seccionSociosAlianza) {
        this.$refs.seccionSociosAlianza.resetItems(2);
      }

      this.dialogRegistro = true;
    },

    /*
     * Obtener las alianzas registradas
     */
    async obtenerAlianzas() {
      this.tableLoading = true;

      this.alianzas = [];
      await this.$store
        .dispatch(OBTENER_INSTRUMENTOS_ALIANZA, { estadoId: 2 })
        .then(res => {
          if (res.status == 200) {
            this.alianzas = res.data;
          }

          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },

    //Obtener todas las alianzas
    async obtenerAlianzasAll() {
      this.tableLoadingAll = true;

      this.alianzasAll = [];
      await this.$store
        .dispatch(OBTENER_INSTRUMENTOS_ALIANZA, { estadoId: 0 })
        .then(res => {
          if (res.status == 200) {
            this.alianzasAll = res.data;
          }

          this.tableLoadingAll = false;
        })
        .catch(() => {
          this.tableLoadingAll = false;
        });
    },

    mostrarModalActualizar(alianzaId) {
      this.modalTitle = "Instrumento alianza";
      this.alianzaId = alianzaId;
      this.accion = 2;
      this.resetDatosSecciones();
      this.dialogRegistro = true;
    },

    resetDatosSecciones() {
      if (this.$refs.seccionDatosGeneralesAlianza) {
        this.$refs.seccionDatosGeneralesAlianza.obtenerTiposInstrumento();
        this.$refs.seccionDatosGeneralesAlianza.obtenerClasificacionesRecursos();

        if (this.accion === 2) {
          this.$refs.seccionDatosGeneralesAlianza.obtenerDatosAlianza(
            this.alianzaId
          );
        }

        this.$refs.seccionDatosGeneralesAlianza.resetItems(1);
      }

      if (this.$refs.seccionVinculacionAlianza) {
        this.$refs.seccionVinculacionAlianza.resetItems(2);
        this.$refs.seccionVinculacionAlianza.obtenerCoberturaProyecto(
          this.alianzaId
        );
      }

      if (this.$refs.seccionSociosAlianza) {
        this.$refs.seccionSociosAlianza.resetItems(1);
        this.$refs.seccionSociosAlianza.obtenerSocios(this.alianzaId);
        this.$refs.seccionSociosAlianza.obtenerTiposSocio();
      }

      if (this.$refs.seccionCoberturaAlianza) {
        this.$refs.seccionCoberturaAlianza.resetItems(1);
        this.$refs.seccionCoberturaAlianza.obtenerCoberturas(this.alianzaId);
        this.$refs.seccionCoberturaAlianza.obtenerDepartamentos();
      }

      if (this.$refs.seccionContactosAlianza) {
        this.$refs.seccionContactosAlianza.obtenerCargos().then(() => {
          this.$refs.seccionContactosAlianza.obtenerTiposContacto().then(() => {
            this.$refs.seccionContactosAlianza.obtenerContactos(this.alianzaId);
          });
        });
      }
    },

    /*
     * Habilitar las secciones para actualizar la información
     */
    habilitarSecciones() {
      this.accion = 2;
    },

    ///Actualizar el id de la alianza
    actualizarAlianzaId(nuevoId) {
      this.alianzaId = nuevoId;
    },

    /*
     * Mostrar el modal para el envío de la alianza
     */
    mostrarModalAprobacionRechazo(tipo, id, nombre) {
      this.alianzaId = id;
      this.nombreInstrumento = nombre;
      tipo === 1 ? (this.nuevoEstado = 3) : (this.nuevoEstado = 8);
      this.dialogEnvio = true;
    },

    //Mostrar el dialogo para enviar correcciones
    mostrarDialogCorreccion(id, descripcion) {
      this.nombreInstrumento = descripcion;
      this.alianzaId = id;
      this.nuevoEstado = 9;
      this.dialogCorreccion = true;
    },

    async enviarAlianza() {
      this.btnEnvioLoading = true;
      this.btnAprobacionLoading = true;

      this.$store
        .dispatch(ENVIAR_ALIANZA, {
          instrumentoAlianzaId: this.alianzaId,
          estadosAlianzasId: this.nuevoEstado,
          comentarios: this.comentarios
        })
        .then(res => {
          this.btnEnvioLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.comentarios = "";
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerAlianzas();
            this.obtenerAlianzas();
            this.dialogEnvio = false;
            this.dialogCorreccion = false;
            // this.resetForm();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEnvioLoading = false;
          this.btnAprobacionLoading = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
          this.btnEnvioLoading = false;
          this.btnAprobacionLoading = false;
        });
    },

    //Descargar el archivo
    descargarArchivo(path) {
      DownloadFile.download(path);
    },

    async mostrarHistorialNegociacion(id) {
      this.datosHistorial = [];
      //let datos = [];
      this.skeletonHistorial[id] = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `InstrumentoAlianza/historial/${id}`
        })
        .then(res => {
          if (res.status === 200) {
            this.datosHistorial = res.data;
            //this.datosHistorial = datos.replaceAll('\n', '<br/>');
            //this.datosHistorial = datos.map(str => str.replace(/(?:\r\n|\r|\n)/g, '<br/>'));
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.skeletonHistorial[id] = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error al obtener el historial!`
          );
          this.skeletonHistorial[id] = false;
          this.datosHistorial = [];
        });
    },

    addBr(str) {
      const result = str.split("\n").join("<BR />");
      return result;
    }
  },

  created() {
    this.obtenerAlianzas();
    this.obtenerAlianzasAll();
    this.comentarios = "";
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "dashboard" },
      { title: "Alianzas" }
    ]);
  },

  computed: {
    headers() {
      return [
        {
          text: "Codigo",
          align: "start",
          sortable: false,
          value: "codigo"
        },
        {
          text: "Nombre del instrumento",
          align: "start",
          sortable: true,
          value: "nombreInstrumento"
        },
        {
          text: "Fecha Suscripción",
          align: "start",
          sortable: true,
          value: "fechaSuscripcion"
        },
        {
          text: "Fecha Finalización",
          align: "start",
          sortable: true,
          value: "fechaFinalizacion"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado",
          filter: this.filtroEstado
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    },

    headersAll() {
      return [
        {
          text: "Codigo",
          align: "start",
          sortable: false,
          value: "codigo"
        },
        {
          text: "Nombre del instrumento",
          align: "start",
          sortable: true,
          value: "nombreInstrumento"
        },
        {
          text: "Fecha Suscripción",
          align: "start",
          sortable: true,
          value: "fechaSuscripcion"
        },
        {
          text: "Fecha Finalización",
          align: "start",
          sortable: true,
          value: "fechaFinalizacion"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado",
          filter: this.filtroEstado
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    }
  }
};
</script>
