<template>
<div>
    <div v-if="skeletonLoading">
        <v-row>
            <v-col cols="12" md="12">
                <v-skeleton-loader
                v-bind="attrs"
                min-height="600px"
                type="article, article, article, actions"
                >
                </v-skeleton-loader>
            </v-col>
        </v-row>
    </div>

    <div v-if="!skeletonLoading">

        <v-card class="mt-6" outlined>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                        <v-subheader class="text-h6 black--text">
                            Datos Generales
                        </v-subheader>
                    </v-col>
                </v-row>

                <v-form ref="form" v-on:submit.prevent="cargarArchivo" v-model="validForm">
                    <v-row>
                        <!--inicio:: campo -->
                        <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
                            <v-text-field
                                dense
                                filled
                                v-model="datosItem.nombreInstrumento"
                                class="required"
                                color="blue-grey lighten-2"
                                label="Nombre del instrumento"
                                :rules="[
                                    required('nombre del instrumento'),
                                    minLength('nombre del instrumento', 5),
                                    maxLength('nombre del instrumento', 500)
                                ]"
                                :disabled="tipo===1"
                                maxlength="500"
                            >
                            </v-text-field>
                        </v-col>
                        <!--fin:: campo-->
                    </v-row>


                    <v-row>
                        <!--inicio:: campo-->
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-autocomplete
                                v-model="institucionSeleccionada"
                                :items="instituciones"
                                :loading="institucionesLoading"
                                dense
                                filled
                                class="required"
                                label="Institución rectora"
                                item-text="nombreInstitucion"
                                item-value="id"
                                return-object
                                :rules="[
                                    selectRequired('institución rectora'),
                                ]"
                                :no-data-text="
                                instituciones != null
                                    ? 'Selecciona una institución rectora'
                                    : 'No se han encontrado unidades rectoras'
                                "
                                menu-props="auto"
                                :disabled="true"
                                
                                >
                            </v-autocomplete>
                        </v-col>
                        <!--fin:: campo-->
                    </v-row>

                    <v-row>
                        <!--inicio:: campo -->
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-textarea
                                dense
                                filled
                                v-model="datosItem.objetivo"
                                class="required"
                                color="blue-grey lighten-2"
                                label="Objetivo"
                                :rules="[
                                    required('objetivo'),
                                    minLength('objetivo', 5),
                                    maxLength('objetivo', 1000)
                                ]"
                                :disabled="tipo===1"
                                rows="2"
                                maxlength="500"
                            >
                            </v-textarea>
                        </v-col>
                        <!--fin:: campo-->
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class=""
                                color="blue-grey lighten-2"
                                v-model="montoSuscrito"
                                label="Monto suscrito"
                                :rules="[
                                    montoSuscrito.length > 0 ? decimals('monto suscrito') : true
                                ]"
                                :disabled="tipo===1"
                                maxlength="30"
                                prefix="GTQ"
                            ></v-text-field>
                        </v-col>
                    </v-row>


                    <v-row>
                        <!--inicio:: campo -->
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-select
                                dense
                                filled
                                v-model="cuentaTemporalidadSeleccionada"
                                :items="cuentaTemporalidadItems"
                                class="required"
                                color="blue-grey lighten-2"
                                label="La alianza cuenta con temporalidad"
                                item-text="text"
                                item-value="id"
                                return-object
                                :rules="[selectRequired('temporalidad')]"
                                menu-props="auto"
                                @change="configurarFechaFinalizacion"
                                :disabled="tipo===1"
                            >
                            </v-select>
                        </v-col>
                        <!--fin:: campo-->


                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="fechaSuscripcion"
                                label="Fecha de inicio"
                                :rules="[
                                    required('fecha de inicio'),
                                    minLength('fecha de inicio', 8),
                                    dateFormat('fecha de inicio')
                                ]"
                                maxlength="10"
                                v-mask="'##/##/####'"
                                :disabled="tipo===1"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="fechaFinalizacion"
                                :disabled="cuentaTemporalidadSeleccionada.id===2 || tipo===1"
                                label="Fecha de fin"
                                :rules="[
                                    required('fecha de fin'),
                                    minLength('fecha de fin', 8),
                                    dateFormat('fecha de fin')
                                ]"
                                maxlength="10"
                                v-mask="'##/##/####'"
                                
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <template>
                                <v-file-input v-if="tipo===2"
                                    id="respaldoFile"
                                    dense
                                    filled
                                    placeholder="Seleccionar pdf"
                                    label="Documento de formalización"
                                    append-icon="mdi-file-pdf-outline"
                                    prepend-icon=""
                                    accept="application/pdf,image/jpeg"
                                    @change="onFileChange"
                                    :rules="[
                                        accion===1 ? fileRequired('Documento de formalización') : true]"
                                    :show-size="1000"
                                    ref="respaldoFile"
                                    :class="accion===1 ? `required` :``"
                                    >
                                    <template v-slot:selection="{ index, text }">
                                        <v-chip
                                        v-if="index < 2"
                                        small
                                        label
                                        color="primary"
                                        >
                                        {{ text }}
                                        </v-chip>
                                        <span
                                        v-else-if="index === 2"
                                        class="
                                            text-overline
                                            grey--text
                                            text--darken-3
                                            mx-2
                                        "
                                        >
                                        +{{ files.length - 2 }} archivo(s)
                                        </span>
                                    </template>
                                </v-file-input>
                            </template>
                        </v-col>

                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-4" v-if="accion===2 && datosItem.documentoFormalizacion">
                            <v-btn
                                color="light-blue-502"
                                dark
                                dense
                                filled
                                class="white--text mb-2 float-left"
                                @click="descargarArchivo(datosItem.documentoFormalizacion)"
                            >
                                <v-icon left>mdi-download</v-icon>
                                Descargar documento formalización
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <!--inicio:: campo -->
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-select
                                dense
                                filled
                                v-model="datosItem.tipoInstrumentoAlianzasId"
                                :items="tiposInstrumento"
                                class="required"
                                color="blue-grey lighten-2"
                                label="Tipo de instrumento"
                                item-text="nombreTipo"
                                item-value="id"
                                :rules="[selectRequired('tipo de insturmento')]"
                                menu-props="auto"
                                :disabled="tipo===1"
                                
                            >
                            </v-select>
                        </v-col>
                        <!--fin:: campo-->

                         <!--inicio:: campo -->
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-select
                                dense
                                filled
                                v-model="datosItem.clasificacionRecursosId"
                                :items="clasificacionRecursos"
                                class="required"
                                color="blue-grey lighten-2"
                                label="Clasificación de recursos"
                                item-text="recursos"
                                item-value="id"
                                :rules="[selectRequired('clasificación recursos')]"
                                menu-props="auto"
                                :disabled="tipo===1"
                               
                            >
                            </v-select>
                        </v-col>
                        <!--fin:: campo-->
                    </v-row>

                    <v-row v-if="tipo===2">
                        <v-col cols="12" sm="12" md="12">
                            <v-btn
                                color="light-blue-502"
                                dark
                                dense
                                filled
                                class="white--text mb-2 float-right"
                                type="submit"
                                @disabled="!validForm"
                                :loading="btnRegistroLoading"
                            >
                                {{accion===1 ? `Registrar información` : `Actualizar información`}}
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-form>

            </v-card-text>
        </v-card>


        <!--Dialog loader -->
        <DialogLoader
            :dialogVisible="dialogLoaderVisible"
            :text="dialogLoaderText"
            transition="scroll-y-transition"
        ></DialogLoader>
        <!---->

        <!--Inicio:: Snack alert-->
        <SnackAlert ref="snackalert"></SnackAlert>
        <!-- Fin:: Snack alert-->
    </div>
</div>
</template>

<script>
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
import DownloadFile from "@/core/untils/downloadfile.js";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import { REGISTRAR_INSTRUMENTO_ALIANZA, OBTENER_INSTRUMENTO_ALIANZA, ACTUALIZAR_INSTRUMENTO_ALIANZA } from "@/core/services/store/alianzas/instrumentoalianza.module";
import { CARGAR_ARCHIVO } from "@/core/services/store/cargaarchivo.module";
import moment from "moment";

export default {
    name: "SeccionDatosGeneralesAlianza",
    props: ['alianzaId', 'accion', 'tipo'], //Tipo 1 segepla, 2 externo, si es 2 bloquear los controles
    components: {
        DialogLoader,
        SnackAlert
    },

    data(){
        return { 
           
            validForm: false,
            skeletonLoading: false,
            dialogLoaderVisible: false,
            dialogLoaderText: "",
            btnRegistroLoading: false,
            btnRegistroText: "Registrar alianza",
            datosItem: {
                nombreInstrumento: "",
                objetivo: "",
                temporalidadAlianza: 0,
                fechaSuscripcion: "",
                fechaFinalizacion: "",
                montoSuscrito: "",
                documentoFormalizacion: "",
                tipoInstrumentoAlianzasId: 0,
                clasificacionRecursosId: 0,
                estadosId: 1,
                usuarioCreacion: 'admin'
            },
            documentoReemplazado: false,
            cuentaTemporalidadSeleccionada: {},
            fechaSuscripcion: "",
            fechaFinalizacion: "",
            documentoFormalizacion:[],
            cuentaTemporalidadItems: [{id: 1, text:"Si"}, {id: 2, text:"No"}],
            tiposInstrumento: [],
            clasificacionRecursos: [],
            archivoCargado: false,
            montoSuscrito:"",
            ddInstitucionesLoading: false,
            institucionSeleccionada:{},
            instituciones: [],
            ...validations
        }
    },

    methods: {

        resetItems(tipo){
            if(tipo===1){
                this.skeletonLoading=true;
            }
            this.institucionSeleccionada={};
            this.fechaSuscripcion = "";
            this.fechaFinalizacion = "";
            this.datosItem= {
                nombreInstrumento: "",
                objetivo: "",
                fechaSuscripcion: "",
                fechaFinalizacion: "",
                montoSuscrito: "",
                documentoFormalizacion: "",
                tipoInstrumentoAlianzasId: 0,
                clasificacionRecursosId: 0,
                temporalidadAlianza: 0,
                estadosId: 1,
                usuarioCreacion: 'admin'
            };

            this.montoSuscrito="";

        },

        configurarFechaFinalizacion(){
           // console.log(this.cuentaTemporalidadSeleccionada.id)
            if(this.cuentaTemporalidadSeleccionada.id === 2){
                this.fechaFinalizacion = "31/12/2032";
            } else {
                this.fechaFinalizacion = "";
            }
        },


        onFileChange(e) {
            if (e) {
                //!?Validar que el tipo de archivo sea pdf
                if (e.type != "application/pdf" && e.type != "image/jpeg") {
                    // Api call
                    this.$refs.snackalert.SnackbarShow("warning","Alerta",`El archivo que desea ingresar no es válido.`);
                    //console.log("no es un pdf..")
                    //this.$refs.file.reset();
                    document.querySelector("#respaldoFile").value = "";
                    this.$refs.respaldoFile.reset();
                    return false;
                }
                //?Validar que el tamaó del archivo no exceda los 10Mb
                if (e.size / 1024 / 1024 > 10) {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta",`El tamaño del archivo excede el límite permitido (10Mb)`);
                    // this.$refs.file.reset();
                    document.querySelector("#respaldoFile").value = "";
                    this.$refs.respaldoFile.reset();
                    return false;
                }

                if(this.accion===2){
                    this.documentoReemplazado = true;
                }
                this.documentoFormalizacion = e;
            }
        },


        //Obtener las instituciones
        async obtenerInstituciones() {
        this.ddInstitucionesLoading=true;
        
        this.instituciones = [];
        await this.$store                                                       
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `Entidades/all/1`})
            .then(res => {
            if(res.status==200){

                this.instituciones = res.data;
            }
            this.ddInstitucionesLoading=false;
            
            })
            .catch(() => {
              this.ddInstitucionesLoading=false;
              this.instituciones=[];
            });
        },


        /*
        * Obtener datos de la alianza
        */
        async obtenerDatosAlianza(alianzaId){
            this.accion = 2;
            this.datosItem = [];
            this.montoSuscrito = "";
            await this.$store
            .dispatch(OBTENER_INSTRUMENTO_ALIANZA, alianzaId)
            .then(res => {
                if (res.status === 200) {
                    this.datosItem = res.data;
                    this.datosItem.usuarioActualizacion = "admin";
                    this.fechaSuscripcion = this.datosItem.fechaSuscripcion;
                    this.fechaFinalizacion = this.datosItem.fechaFinalizacion;
                    this.datosItem.montoSuscrito ? this.montoSuscrito = this.datosItem.montoSuscrito : this.montoSuscrito="";


                    //Seleccionar la institución
                    if(this.datosItem.ENTIDAD_ID){
                        this.institucionSeleccionada = this.instituciones.find(item => item.id === this.datosItem.ENTIDAD_ID);
                    }

                    if(this.datosItem.temporalidadAlianza == 0 ){
                        this.cuentaTemporalidadSeleccionada = { id: 2, text:"No"};
                    } else {
                        this.cuentaTemporalidadSeleccionada = { id: 1, text:"Si"};
                    }
                }
                this.skeletonLoading = false;
            })
            .catch(() => {
                this.datosItem = [];
            });
        },

        /*
        * Obtener los tipos de instrumento
        */
        async obtenerTiposInstrumento() {
            this.tiposInstrumento = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TipoInstrumentoAlianzas/all/1'})
            .then(res => {
                if (res.status === 200) {
                    this.tiposInstrumento = res.data;
                }
            })
            .catch(() => {
                this.tiposInstrumento = [];
            });
        },


        /*
        * Obtener las clasificaciones de recursos
        */
        async obtenerClasificacionesRecursos() {
            this.clasificacionRecursos = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'ClasificacionRecursos/all/1'})
            .then(res => {
                if (res.status === 200) {
                    this.clasificacionRecursos = res.data;
                }
            })
            .catch(() => {
                this.clasificacionRecursos = [];
            });
        },


         async cargarArchivo(){

            if(!this.institucionSeleccionada){
                this.$refs.snackalert.SnackbarShow('warning', 'Alerta', `Debe seleccionar una institución para poder registrar el instrumento`);
                return;
            }



            this.btnRegistroLoading=true;
            const file = this.documentoFormalizacion;


            if(this.accion===1){
                await 
                this.$store.dispatch(CARGAR_ARCHIVO, {file:file, path: '\\ArchivosSigeaci\\cns\\documentosot\\'})
                .then(res => {
                    //console.log(res)
                    if(res.status===200){
                        this.archivoCargado = true;
                        this.datosItem.documentoFormalizacion = res.pathArchivo;
                        this.registrarItem();
                        return true;
                    }
                    else {
                        this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
                        this.archivoCargado = false;
                        this.btnRegistroLoading=false;
                        return false;
                    
                    }
                })
                .catch(() => {
                    //console.log(error)
                    this.btnRegistroLoading=true;
                    return false;
                    
                });
            }

            if(this.accion===2){
                if(this.documentoReemplazado===true){
                    await 
                    this.$store.dispatch(CARGAR_ARCHIVO, {file:file, path: '\\ArchivosSigeaci\\cns\\documentosot\\'})
                    .then(res => {
                        //console.log(res)
                        if(res.status===200){
                            this.archivoCargado = true;
                            this.datosItem.documentoFormalizacion = res.pathArchivo;
                            this.registrarItem();
                            return true;
                        }
                        else {
                            this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
                            this.archivoCargado = false;
                            this.btnRegistroLoading=false;
                            return false;
                        
                        }
                    })
                    .catch(() => {
                        //console.log(error)
                        this.btnRegistroLoading=true;
                        return false;
                        
                    });
                } else {
                     this.registrarItem();
                }

            }
        },


        async registrarItem(){
            

            this.datosItem.fechaSuscripcion = moment(this.fechaSuscripcion, "DD-MM-YYYY").format("YYYY-MM-DD");
            this.datosItem.fechaFinalizacion = moment(this.fechaFinalizacion, "DD-MM-YYYY").format("YYYY-MM-DD");
            this.cuentaTemporalidadSeleccionada.id === 2 ? this.datosItem.temporalidadAlianza = 0 : this.datosItem.temporalidadAlianza = 1;
            this.datosItem.usuarioCreacion ='admin';
            this.datosItem.montoSuscrito = parseFloat(this.montoSuscrito);

            this.datosItem.ENTIDAD_ID = this.institucionSeleccionada.id;

            //Quitar la propiedad si es 0 o no se ingresó un número
            if(this.montoSuscrito.length <= 0 || this.datosItem.montoSuscrito === "0" || parseFloat(this.datosItem.montoSuscrito) <= 0 ){
                delete this.datosItem.montoSuscrito;
            } 

            if(this.accion===1){


                //this.datosRiesgo.proyectoCNSId = this.id;
                await this.$store
                .dispatch(REGISTRAR_INSTRUMENTO_ALIANZA, this.datosItem)
                .then(res => {
                    //console.log(res)
                    if(res.status===200){
                        
                        this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
                        //this.resetItems();
                        this.$emit('get-items');
                        this.$emit('habilitar-secciones');
                        this.alianzaId = res.data;
                        this.$emit('actualizar-alianza-id', res.data);
                        this.btnRegistroText = "Actualizar información";
                        this.documentoReemplazado=false;
                        
                    } else {
                        this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
                    }
                    this.btnRegistroLoading=false;
                })
                .catch(error => {
                    //console.log(error)
                    this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
                    this.btnRegistroLoading=false;
                });
            } else if(this.accion===2){
                
                //this.datosRiesgo.proyectoCNSId = this.id;
                await this.$store
                .dispatch(ACTUALIZAR_INSTRUMENTO_ALIANZA, this.datosItem)
                .then(res => {
                    //console.log(res)
                    if(res.status===200){
                        
                        this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
                        //this.resetItems();
                        this.$emit('get-items');
                        this.$emit('habilitar-secciones');
                        this.alianzaId = res.data;
                        this.$emit('actualizar-alianza-id', res.data);
                        this.btnRegistroText = "Actualizar información";
                        this.documentoReemplazado=false;
                        
                    } else {
                        this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
                    }
                    this.btnRegistroLoading=false;
                })
                .catch(error => {
                    //console.log(error)
                    this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
                    this.btnRegistroLoading=false;
                });
            }

        },


        //Descargar el archivo
        descargarArchivo(path){
            DownloadFile.download(path);
        },
    },

    created() {
        this.resetItems(1);
        this.obtenerInstituciones();

        this.obtenerTiposInstrumento().then(()=> {

            this.obtenerClasificacionesRecursos().then(()=> {
                if(this.accion == 2){
                    this.obtenerDatosAlianza(this.alianzaId);
                }
                this.skeletonLoading = false;
            });
        });

        
        
    }
}
</script>
<style lang="">
    
</style>