var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-skeleton-loader',{attrs:{"min-height":"600px","type":"article, article, article, actions"}})],1)],1)],1):_vm._e(),(!_vm.skeletonLoading)?_c('div',[_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Socios alianza ")]),_c('v-divider',{staticClass:"mt-0 pt-0"})],1)],1),(_vm.tipo===2)?_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.registrarItem.apply(null, arguments)}},model:{value:(_vm.validFormSocio),callback:function ($$v) {_vm.validFormSocio=$$v},expression:"validFormSocio"}},[_c('v-row',[_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","color":"blue-grey lighten-2","label":"Nombre del socio","rules":[
                                    _vm.required('nombre del socio'),
                                    _vm.minLength('nombre del socio', 5),
                                    _vm.maxLength('nombre del socio', 100)
                                ],"maxlength":"100"},model:{value:(_vm.datosItem.socio),callback:function ($$v) {_vm.$set(_vm.datosItem, "socio", $$v)},expression:"datosItem.socio"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-6 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"dense":"","filled":"","items":_vm.tiposSocio,"color":"blue-grey lighten-2","label":"Tipo de socio","item-text":"tipo","item-value":"id","rules":[
                                    _vm.selectRequired('tipo de socio')
                                ],"menu-props":"auto"},model:{value:(_vm.datosItem.tipoSocioAlianzaId),callback:function ($$v) {_vm.$set(_vm.datosItem, "tipoSocioAlianzaId", $$v)},expression:"datosItem.tipoSocioAlianzaId"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue-502","dark":"","dense":"","filled":"","type":"submit","loading":_vm.btnRegistroLoading},on:{"disabled":function($event){!_vm.validFormSocio}}},[_vm._v(" Registrar socio ")])],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-6"},[(_vm.tipo===2)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Socios registrados ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.sociosAlianza,"loading":_vm.tableLoading,"footer-props":{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                'items-per-page-text': 'Registros por página',
                                pageText: '{0}-{1} de {2}'
                            }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                            var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.socio))]),_c('td',[_vm._v(_vm._s(item.tipo))]),_c('td',[_c('v-chip',{staticClass:"ma-2 font-weight-medium",attrs:{"label":"","color":item.estadosId === 1
                                                    ? 'blue lighten-4'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-4'
                                                    : item.estadosId === 6
                                                    ? 'cyan lighten-4'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-4'
                                                    : 'pink lighten-4',"text-color":item.estadosId === 1
                                                    ? 'blue lighten-1'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-1'
                                                    : item.estadosId === 6
                                                    ? 'cyan darken-1'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-1'
                                                    : 'pink darken-1',"small":""}},[_vm._v(" "+_vm._s(item.estado)+" ")])],1),_c('td',[(item.estadosId==1 && _vm.tipo===2)?_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"small":"","depressed":"","disabled":_vm.btnEliminarDisabled,"color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.eliminarItem(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Dar de baja ")],1):_vm._e()],1)])]}}],null,false,2046974153)})],1)],1)],1)],1)],1):_vm._e(),_c('DialogLoader',{attrs:{"dialogVisible":_vm.dialogLoaderVisible,"text":_vm.dialogLoaderText,"transition":"scroll-y-transition"}}),_c('SnackAlert',{ref:"snackalert"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }