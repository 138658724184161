<template>
<div>
    <div v-if="skeletonLoading">
        <v-row>
            <v-col cols="12" md="12">
                <v-skeleton-loader
                min-height="600px"
                type="article, article, article, actions"
                >
                </v-skeleton-loader>
        </v-col>
        </v-row>
    </div>

    <div v-if="!skeletonLoading">
    <!--inicio:: condiciones de instituciones beneficiarias -->
        <v-card class="mt-6" outlined>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                        <v-subheader class="text-h6 black--text">
                            Socios alianza
                        </v-subheader>
                
                        <v-divider class="mt-0 pt-0"></v-divider>
                    </v-col>
                </v-row>

                <v-form ref="form" v-on:submit.prevent="registrarItem" v-model="validFormSocio" v-if="tipo===2">
                    <v-row>
                        <!--inicio:: campo -->
                        <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
                            <v-text-field
                                dense
                                filled
                                v-model="datosItem.socio"
                                class="required"
                                color="blue-grey lighten-2"
                                label="Nombre del socio"
                                :rules="[
                                    required('nombre del socio'),
                                    minLength('nombre del socio', 5),
                                    maxLength('nombre del socio', 100)
                                ]"
                                maxlength="100"
                            >
                            </v-text-field>
                        </v-col>
                        <!--fin:: campo-->
                    </v-row>

                    <v-row>
                        <!--inicio:: campo -->
                        <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
                            <v-select
                                dense
                                filled
                                v-model="datosItem.tipoSocioAlianzaId"
                                :items="tiposSocio"
                                class="required"
                                color="blue-grey lighten-2"
                                label="Tipo de socio"
                                item-text="tipo"
                                item-value="id"
                                :rules="[
                                    selectRequired('tipo de socio')
                                ]"
                                menu-props="auto"
                            >
                            </v-select>
                        </v-col>
                        <!--fin:: campo-->
                    </v-row>

                     <v-row>
                        <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                            <v-btn
                                color="light-blue-502"
                                dark
                                dense
                                filled
                                class="white--text mb-2 float-right"
                                type="submit"
                                @disabled="!validFormSocio"
                                :loading="btnRegistroLoading"
                            >
                                Registrar socio
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-form>

                <v-row class="mt-6">
                    <v-col cols="12" sm="12" md="12" v-if="tipo===2">
                        <v-subheader class="text-h6 black--text">
                            Socios registrados
                        </v-subheader>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                        <v-data-table
                            class="elevation-1"
                            :headers="headers"
                            :items="sociosAlianza"
                            :loading="tableLoading"
                            :footer-props="{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-page-first',
                                lastIcon: 'mdi-page-last',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                'items-per-page-text': 'Registros por página',
                                pageText: '{0}-{1} de {2}'
                            }"
                            >

                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td>{{ item.id }}</td>
                                        <td>{{ item.socio }}</td>
                                        <td>{{ item.tipo }}</td>                                        
                                        <td>
                                            <v-chip
                                                class="ma-2 font-weight-medium"
                                                label
                                                :color="
                                                    item.estadosId === 1
                                                    ? 'blue lighten-4'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-4'
                                                    : item.estadosId === 6
                                                    ? 'cyan lighten-4'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-4'
                                                    : 'pink lighten-4'
                                                "
                                                :text-color="
                                                    item.estadosId === 1
                                                    ? 'blue lighten-1'
                                                    : item.estadosId === 2
                                                    ? 'deep-orange lighten-1'
                                                    : item.estadosId === 6
                                                    ? 'cyan darken-1'
                                                    : item.estadosId === 7
                                                    ? 'deep-orange lighten-1'
                                                    : 'pink darken-1'
                                                "
                                                small
                                                >
                                                {{ item.estado }}
                                            </v-chip>
                                        </td>
                                        <td>
                                            <v-btn v-if="item.estadosId==1 && tipo===2"
                                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                                small
                                                depressed
                                                :disabled="btnEliminarDisabled"
                                                color="blue-grey lighten-5"
                                                @click="eliminarItem(item.id)"
                                                >
                                                <v-icon left>mdi-delete</v-icon> Dar de baja
                                            </v-btn>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>

     <!--Dialog loader -->
    <DialogLoader
        :dialogVisible="dialogLoaderVisible"
        :text="dialogLoaderText"
        transition="scroll-y-transition"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
</div>
</template>

<script>
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";

import {OBTENER_SOCIOS_INSTRUMENTO_ALIANZA, REGISTRAR_SOCIO_INSTRUMENTO_ALIANZA, ELIMINAR_SOCIO_INSTRUMENTO_ALIANZA} from "@/core/services/store/alianzas/socioalianza.module";
export default {
    name: "SeccionSociosAlianza",
    props: ['alianzaId', 'accion', 'tipo'], //Tipo 1 segepla, 2 externo, si es 2 bloquear los controles
    components:{
        SnackAlert,
        DialogLoader
    },
    data(){
        return{ 
            skeletonLoading: false,
            validFormSocio: false,
            tableLoading: false,
            btnRegistroLoading: false,
            sociosAlianza:[],
            tiposSocio: [],
            btnEliminarDisabled: false,
            datosItem: {
                id: 0,
                socio: "",
                tipoSocioAlianzaId: 0,
                instrumentoAlianzaId: this.alianzaId,
                estadosId: 1,
                usuarioCreacion: "admin",
            },
            ...validations
        }
    },

    methods: {
        resetItems(tipo){
            if(tipo===1){
                this.skeletonLoading = true;
            }
            this.datosItem= {
                id: 0,
                socio: "",
                tipoSocioAlianzaId: 0,
                instrumentoAlianzaId: this.alianzaId,
                estadosId: 1,
                usuarioCreacion: "admin",
            };
        },

         /*
        * Obtener los socios registrados de la alianza
        */
        async obtenerSocios(alianzaId) {
            this.tableLoading=true;
            this.sociosAlianza = [];
            await this.$store
            .dispatch(OBTENER_SOCIOS_INSTRUMENTO_ALIANZA, {estadoId: 1,alianzaId: alianzaId})
            .then(res => {
                if (res.status === 200) {
                    this.sociosAlianza = res.data;
                    
                }
                this.skeletonLoading = false;
                this.tableLoading=false;
            })
            .catch(() => {
                this.sociosAlianza = [];
                this.tableLoading=false;
            });
        },

        /*
        * Obtener los tipos de socios alianza
        */
        async obtenerTiposSocio() {
            this.tiposSocio = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TipoSocioAlianza/all/1'})
            .then(res => {
                if (res.status === 200) {
                    this.tiposSocio = res.data;
                }
            })
            .catch(() => {
                this.tiposSocio = [];
            });
        },

        /*
        * Registrar socio
        */
        async registrarItem(){
            this.btnRegistroLoading = true;
            //this.datosRiesgo.proyectoCNSId = this.id;
            this.datosItem.instrumentoAlianzaId = this.alianzaId;
            //console.log(this.datosItem)
            await this.$store
            .dispatch(REGISTRAR_SOCIO_INSTRUMENTO_ALIANZA, this.datosItem)
            .then(res => {
                //console.log(res)
                if(res.status===200){
                    this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
                    this.obtenerSocios(this.alianzaId);
                    this.resetItems(2);
                } else {
                    this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
                }
                this.btnRegistroLoading=false;
            })
            .catch(error => {
                //console.log(error)
                this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
                this.btnRegistroLoading=false;
            });

        },

        async eliminarItem(id){
            this.btnEliminarDisabled=true;
            await this.$store
            .dispatch(ELIMINAR_SOCIO_INSTRUMENTO_ALIANZA, id)
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.obtenerSocios(this.alianzaId);             
                    this.resetItems(2);
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnEliminarDisabled=false;
            })
            .catch(error => {
                
                 this.btnEliminarDisabled=false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        }
    },

    created(){
        this.resetItems(1);
        this.obtenerSocios(this.alianzaId);
        this.obtenerTiposSocio();

    },

    computed: {
        headers(){
        return [
            {
                text: "Id",
                //align: "start",
                sortable: false,
                value: "id",
                align: 'start'
            },
            {
                text: "Nombre del socio",
                align: "start",
                sortable: true,
                value: "socio"
            },
             {
                text: "Tipo de socio",
                align: "start",
                sortable: true,
                value: "tipo"
            },
             {
                text: "Estado",
                align: "start",
                sortable: true,
                value: "estado"
            },
            {
                text: "Acciones",
                align: "start",
                sortable: false,
                value: ""
            }
        ]
    }
    }
}
</script>

